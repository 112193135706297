import { Skeleton, Typography } from '@mui/material';
import { styled } from '@mui/system';
interface PricesContainerProp {
  asRow: boolean;
}
interface PriceContainerProps {
  existsDiscountInCarousel?: boolean;
}

const Card = styled('a')<{ listLength: number; inCarousel?: boolean }>(
  ({ theme, listLength, inCarousel }) => ({
    textDecoration: 'none',
    scrollSnapAlign: 'start',
    position: 'relative',
    minHeight: 240,
    width: '100%',
    background: inCarousel ? 'transparent' : '#FFFFFF',
    border: inCarousel ? 'none' : '0.5px solid #EAEAEA',
    cursor: 'pointer',
    padding: '15px 15px 10px 15px',
    [theme.breakpoints.up(320)]: {
      width: `${Math.max(100 / listLength, 50)}%`,
      minWidth: 150,
    },

    [theme.breakpoints.up(470)]: {
      width: `${Math.max(100 / listLength, 33.3)}%`,
    },
    [theme.breakpoints.up(620)]: {
      width: `${Math.max(100 / listLength, 25)}%`,
    },
    [theme.breakpoints.up(770)]: {
      width: listLength > 3 ? '33.3%' : 200,
      minWidth: 200,
    },
    [theme.breakpoints.up(820)]: {
      width: listLength > 4 ? '25%' : 200,
    },

    [theme.breakpoints.up(1020)]: {
      width: listLength > 5 ? '20%' : 200,
    },

    [theme.breakpoints.up(1220)]: {
      width: 200,
    },
  }),
);

const MekiIconContainer = styled('div')({
  position: 'absolute',
  top: 15,
  left: 10,
  width: '78px',
  height: '18px',
  zIndex: 1,
});

const BaseSpan = styled('span')({
  display: 'block',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  color: '#757575',
});

const ImageContainer = styled('div')({
  width: '100%',
  maxWidth: 100,
  margin: 'auto',
  height: 80,
  position: 'relative',
});
const Name = styled(BaseSpan)({
  fontWeight: 600,
  fontSize: 14,
  color: '#414046',
});
const ActivePrinciple = styled(BaseSpan)({
  fontSize: 13,
  minHeight: 21,
  maxWidth: 200,
});
const ExtraInfo = styled(BaseSpan)({
  fontSize: 12,
});
const Brand = styled(BaseSpan)({ fontSize: 12 });
const PriceContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'existsDiscountInCarousel',
})<PriceContainerProps>(({ existsDiscountInCarousel }) => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
  alignItems: 'center',
  height: existsDiscountInCarousel ? '45px' : 'auto',
}));

const Price = styled(BaseSpan)({
  fontSize: 16,
  fontWeight: 600,
  color: '#414046',
});

const DiscountPriceContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: '5px',
});

const PricesContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'asRow',
})<PricesContainerProp>(({ asRow }) => ({
  display: 'flex',
  flexDirection: asRow ? 'row' : 'column',
  gap: asRow ? '5px' : '0px',
}));

const DiscountPrice = styled(BaseSpan, {
  shouldForwardProp: (prop) => prop !== 'discountType',
})<{ discountType: string }>(({ discountType }) => ({
  color: discountType == 'EXPIRATION' ? '#603E11' : '#5D5FEF',
  fontFamily: 'Poppins',
  fontSize: '16px',
  fontWeight: '600',
  lineHeight: '24px',
  letterSpacing: '0.02em',
}));

const DiscountChip = styled('div', {
  shouldForwardProp: (prop) => prop !== 'discountType',
})<{ discountType: string }>(({ discountType }) => ({
  backgroundColor: discountType == 'EXPIRATION' ? '#F29B01' : '#5D5FEF',
  width: '37px',
  height: '18px',
  borderRadius: '100px',
  textAlign: 'center',
  gap: '2px',
  position: 'relative',
  top: '2px',
}));

const DiscountChipTypography = styled(Typography)({
  color: '#FFFFFF',
  fontFamily: 'Poppins',
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '18px',
  letterSpacing: '-0.01em',
});

const OriginalPrice = styled(BaseSpan)({
  color: '#CBCBCB',
  textDecoration: 'line-through',
  fontFamily: 'Poppins',
  fontSize: '12px',
  fontWeight: '300',
  lineHeight: '18px',
  letterSpacing: '2%',
  paddingBotton: '0px',
});

const AmountBadge = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#f44336',
  color: 'white',
  borderRadius: '100%',
  position: 'absolute',
  width: 20,
  height: 20,
  top: 16,
  right: 16,
  fontSize: 13,
});

const AveragePrice = styled(BaseSpan)({
  color: '#414046',
  fontSize: 13,
  fontWeight: 400,
});
const StyledSkeleton = styled(Skeleton)`
  margin: 3px 0;
`;
const LoadingOverlay = styled('div')({
  position: 'fixed',
  zIndex: 9999,
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'transparent',
  cursor: 'wait',
});

export {
  Card,
  ImageContainer,
  Name,
  ActivePrinciple,
  ExtraInfo,
  Brand,
  PriceContainer,
  Price,
  AmountBadge,
  StyledSkeleton,
  LoadingOverlay,
  DiscountPriceContainer,
  PricesContainer,
  DiscountPrice,
  OriginalPrice,
  DiscountChip,
  DiscountChipTypography,
  MekiIconContainer,
  AveragePrice,
};
