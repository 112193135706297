/*
 * File: ProductCard.tsx
 * Project: meki
 * File Created: Thursday, 22nd December 2022 4:44:04 pm
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Thursday, 14th December 2023 12:48:43 pm
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2022 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import React, {
  useCallback,
  MouseEvent,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import { useSegment } from '@hooks/useSegment';
import { Product } from '@interfaces';
import { Skeleton } from '@mui/material';
import { getRoute } from '@utils';
import { CurrencyFormatter } from '@utils/formatters/CurrencyFormatter';
import Image from 'next/image';
import Link from 'next/link';
import { AddButton } from './AddButton';
import { useRouter } from 'next/router';
import {
  forwardRef,
  ForwardRefExoticComponent,
  MutableRefObject,
  RefAttributes,
  useState,
} from 'react';
import { AvailabilityChip } from './AvailabilityChip';
import {
  Card,
  ImageContainer,
  Name,
  ActivePrinciple,
  ExtraInfo,
  Brand,
  PriceContainer,
  Price,
  StyledSkeleton,
  LoadingOverlay,
  DiscountPriceContainer,
  DiscountPrice,
  OriginalPrice,
  DiscountChip,
  DiscountChipTypography,
  PricesContainer,
  MekiIconContainer,
  AveragePrice,
} from './productCard.styles';
import { useAddProduct } from '@hooks/useAddProduct';
import { useUpdateQuantity } from '@screens/product/hooks/useUpdateQuantity';
import { usePermissions } from '@hooks/usePermissions';
import { useQuery } from '@apollo/client';
import { useOrderState } from '@hooks/useOrderState';
import { FetchOrderQueryResponse, fetchOrder } from '@queries/order/queries';
import {
  extractConcentrationsLabs,
  extractUnits,
} from '@screens/product/utils/extractExtraInfo';

const currencyFormatter = new CurrencyFormatter({ fallback: '-' });
interface ProductCardProps {
  product: Product;
  index: number;
  listLength: number;
  inCarousel?: boolean;
  location?: string;
  quantityChangeCallback?: (action: 'add' | 'remove') => void;
  existsDiscountInCarousel?: boolean;
}
const extractAveragePriceText = (product: Product) => {
  const { unit, price: regularPrice, quantity } = product;
  const price = product.discount?.price
    ? product.discount?.price ?? regularPrice
    : regularPrice;
  if (!price) return null;
  const formattedUnitPrice = currencyFormatter.format(price / +quantity);
  if (/comprimido/.exec(unit)) {
    return `${formattedUnitPrice} por comp.`;
  }
  if (/cápsula/.exec(unit)) {
    return `${formattedUnitPrice} por cáp.`;
  }
};
const ProductCardComponent = (
  props: ProductCardProps,
  ref: MutableRefObject<HTMLAnchorElement>,
) => {
  // Props and product info
  const {
    product,
    listLength,
    index,
    inCarousel,
    location,
    quantityChangeCallback,
    existsDiscountInCarousel,
  } = props;
  const { name, activePrinciple, price, laboratory, prescriptionType } =
    product;
  const discountPrice = product.discount?.price;
  const listPrice = product.discount?.listPrice as number;
  const cartRef = useRef(false); // Ref to check previous status of cart
  const [imageUrl, setImageUrl] = useState(product?.imagesUrl?.[0]);
  // Hooks
  const { pathname, query } = useRouter();
  const { track } = useSegment();
  const { userCan } = usePermissions();
  const isLocalVendor = userCan('LOCAL_VENDOR');
  const { handleAddProduct } = useAddProduct({
    location: location ?? 'ProductCard',
    index,
  });
  const { addQuantity, subQuantity, setQuantity, quantity } = useUpdateQuantity(
    {
      product,
      location: location ?? 'ProductCard',
      index,
    },
  );

  const [loading, setLoading] = useState(false);

  const addQuantityHandler = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.preventDefault();
      addQuantity();
      if (quantityChangeCallback) {
        quantityChangeCallback('add');
      }
    },
    [quantityChangeCallback, addQuantity],
  );
  const subQuantityHandler = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.preventDefault();
      subQuantity();
      if (quantityChangeCallback) {
        quantityChangeCallback('remove');
      }
    },
    [quantityChangeCallback, subQuantity],
  );

  // Add to Cart handlers
  const handleAddFirstProduct = useCallback(async () => {
    await handleAddProduct({ puuid: product?.uuid, product });
  }, [handleAddProduct, product]);

  const onClickAddProduct = useCallback(
    async (event: MouseEvent<HTMLElement>) => {
      if (isLocalVendor && product?.availability?.status !== 'AVAILABLE_24_HRS')
        return;
      event.preventDefault();
      setLoading(true);
      setQuantity(1);
      if (quantityChangeCallback) {
        quantityChangeCallback('add');
      }
      await handleAddFirstProduct();
      setLoading(false);
    },
    [
      isLocalVendor,
      product?.availability?.status,
      setQuantity,
      quantityChangeCallback,
      handleAddFirstProduct,
    ],
  );

  const { orderUuid } = useOrderState();

  const { data: cart } = useQuery<FetchOrderQueryResponse>(
    fetchOrder('addFetchOrderQuery'),
    {
      variables: {
        params: { uuid: orderUuid },
      },
      skip: !orderUuid,
    },
  );

  const orderDetail = useMemo(() => {
    if (!product?.uuid || !cart?.order) return null;
    return cart?.order?.orderDetails.find((od) => {
      return od.product?.uuid === product?.uuid;
    });
  }, [cart?.order, product?.uuid]);

  const isCheck = prescriptionType === 'CHECK';
  const handleImageError = () => {
    setImageUrl('/assets/images/default.webp');
  };

  const MAX_AMOUNT = isLocalVendor
    ? product?.availability?.stock ?? 0
    : userCan('MANAGE_USER')
    ? Number.MAX_SAFE_INTEGER
    : Math.min(
        6,
        product?.discount?.price && product?.discount?.stockRestriction
          ? product?.availability.discountStock
          : ['UNAVAILABLE', 'HIDDEN', 'SOLD_OUT'].includes(
              product?.availability.statusWithoutStock,
            )
          ? product?.availability.stock ?? 0
          : product?.availability.status ===
            product?.availability.statusWithoutStock
          ? 6
          : ['AVAILABLE', 'REPRESENTATION'].includes(
              product?.availability.statusWithoutStock,
            )
          ? 6
          : product?.availability.stock ?? 6,
      );

  const searchTrackData = () => {
    const list = /buscar/.exec(pathname)
      ? 'search'
      : /\/producto\/.*/.exec(pathname)
      ? 'bioequivalents'
      : /\/categoria\/.*\/sub-categoria\/.*/.exec(pathname)
      ? 'subcategory'
      : '';
    if (list == 'search') {
      return {
        location: 'search',
        search: query?.q as string,
      };
    } else if (list != '') {
      return {
        location: list,
        search: 'null',
      };
    } else {
      return {
        location,
        search: 'null',
      };
    }
  };

  const handleProductClick = () => {
    if (index === -1) return;
    void track('product select', {
      id: product?.id,
      name: product?.name,
      brand: product?.makerBrand ?? product?.laboratory,
      prescriptionType: product?.prescriptionType ?? 'null',
      index,
      category: product?.categories?.map((c) => c.name).join(', ') ?? 'null',
      availability: product?.availability?.status,
      ...searchTrackData(),
    });
  };

  useEffect(
    function setInitialValues() {
      // Only in the first render
      if (!cartRef.current && cart) {
        if (orderDetail) {
          setQuantity(orderDetail.quantity);
        }
      }
      cartRef.current = Boolean(cart);
    },
    [cart, product?.uuid, orderDetail, setQuantity, product?.quantity],
  );

  const discountPercentageRounded = Math.round(
    (((listPrice ?? price) - discountPrice) / (listPrice ?? price)) * 100,
  );
  return (
    <Link
      {...getRoute('product', {
        prodSlug: product?.slug,
      }).toModalLink(pathname, { ...query, location } as Record<
        string,
        string
      >)}
      passHref
    >
      <Card
        ref={ref}
        listLength={listLength}
        onClick={handleProductClick}
        inCarousel={inCarousel}
      >
        {product?.discount?.type == 'MEKI' &&
          !['SOLD_OUT', 'HIDDEN', 'UNAVAILABLE'].includes(
            product.availability.status,
          ) && (
            <MekiIconContainer>
              <Image
                src={'/assets/images/canasta-meki-icon.png'}
                layout="fill"
                alt={`canasta-meki-icon`}
                objectFit="cover"
              />
            </MekiIconContainer>
          )}
        {loading && <LoadingOverlay />}
        {!(
          isLocalVendor && product.availability.status !== 'AVAILABLE_24_HRS'
        ) ? (
          <AddButton
            addOnClick={onClickAddProduct}
            cartQuantity={quantity && orderDetail ? quantity : 0}
            incrementOnClick={addQuantityHandler}
            decrementOnclick={subQuantityHandler}
            productStatus={product?.availability?.status}
            prescriptionType={product?.prescriptionType}
            MAX_AMOUNT={MAX_AMOUNT}
            index={index}
          />
        ) : null}

        <ImageContainer>
          <Image
            src={imageUrl}
            layout="fill"
            alt={`Foto ${product?.name}`}
            objectFit="contain"
            onError={handleImageError}
          />
        </ImageContainer>
        <AvailabilityChip
          availability={product?.availability?.status}
          isCheck={isCheck}
        />
        <Name>{name}</Name>
        <ActivePrinciple>{activePrinciple}</ActivePrinciple>
        <ExtraInfo>{extractConcentrationsLabs(product)}</ExtraInfo>
        <ExtraInfo>{extractUnits(product)}</ExtraInfo>

        <PriceContainer existsDiscountInCarousel={existsDiscountInCarousel}>
          {discountPrice > 0 &&
          !['SOLD_OUT', 'HIDDEN', 'UNAVAILABLE'].includes(
            product.availability.status,
          ) ? (
            <DiscountPriceContainer>
              <PricesContainer asRow={false}>
                <DiscountPrice discountType={product?.discount?.type}>
                  {currencyFormatter.format(discountPrice)}
                </DiscountPrice>
                <OriginalPrice>
                  {currencyFormatter.format(listPrice ?? price)}
                </OriginalPrice>
              </PricesContainer>
              {discountPercentageRounded >= 15 && (
                <DiscountChip discountType={product?.discount?.type}>
                  <DiscountChipTypography>
                    -{discountPercentageRounded}%
                  </DiscountChipTypography>
                </DiscountChip>
              )}
            </DiscountPriceContainer>
          ) : (
            <Price>{currencyFormatter.format(price)}</Price>
          )}
        </PriceContainer>
        <AveragePrice>{extractAveragePriceText(product)}</AveragePrice>
      </Card>
    </Link>
  );
};

const ProductCardSkeleton = (props: Pick<ProductCardProps, 'listLength'>) => {
  return (
    <Card listLength={props.listLength}>
      <ImageContainer>
        <Skeleton variant="rectangular" width={100} height={80} />
      </ImageContainer>

      <AvailabilityChip availability={null} />
      <Name>
        <StyledSkeleton variant="rectangular" width={120} height={15} />
      </Name>
      <ActivePrinciple>
        <StyledSkeleton variant="rectangular" width={80} height={15} />
      </ActivePrinciple>
      <ExtraInfo>
        <StyledSkeleton variant="rectangular" width={70} height={15} />
      </ExtraInfo>
      <Brand>
        <StyledSkeleton variant="rectangular" width={70} height={15} />
      </Brand>
      <Price>
        <StyledSkeleton variant="rectangular" width={50} height={15} />
      </Price>
    </Card>
  );
};

interface IProductCard
  extends ForwardRefExoticComponent<ProductCardProps & RefAttributes<unknown>> {
  Skeleton?: (props: Pick<ProductCardProps, 'listLength'>) => JSX.Element;
}

export const ProductCard: IProductCard = forwardRef(ProductCardComponent);
ProductCard.Skeleton = ProductCardSkeleton;
