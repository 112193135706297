/*
 * File: referralsNoNewUserDialog.tsx
 * Project: meki
 * File Created: Monday, 19th June 2023 11:10:04 am
 * Author: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Last Modified: Friday, 21st July 2023 3:32:00 pm
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2023 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import {
  IconContainer,
  ReferredUserDiv,
  DialogTitleTypography,
  DialogInfoTypography,
  BoldSpan,
  ShareButton,
  ShareButtonDiv,
  LinkTypography,
} from './ReferralsNoNewUserDialog.styles';
import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { Dialog } from '@mui/material';
import { getRoute, removeUndefineds } from '@utils';
import { useModalRouter } from '@hooks/useModalRouter';
import { useRouter } from 'next/router';
import { useOpenModal } from '@hooks/useModalOpenState';
import { useAuth } from '@hooks/useAuth';
import { useMekiState } from '@hooks/useMekiState';
import { useQuery } from '@apollo/client';
import {
  ValidateReferenceCouponParams,
  ValidateReferenceCouponQuerie,
  ValidateReferenceCouponResponse,
} from '@queries/referenceCoupon/queries';

export function ReferralsNoNewUserDialog() {
  const modalRouter = useModalRouter();
  const router = useRouter();
  const { codigo: code } = router.query;
  const codeString = code ? decodeURI(code as string) : '';
  const { appUser: user } = useAuth();
  const [open, setOpen] = useState(Boolean(Boolean(codeString) && user));
  const { isModalOpen } = useOpenModal();
  const { setReferralCode, referralCode } = useMekiState();
  const { data: { validateReferenceCoupon: cachedDataCode } = {} } = useQuery<
    ValidateReferenceCouponResponse,
    ValidateReferenceCouponParams
  >(ValidateReferenceCouponQuerie(), {
    variables: { params: { code: referralCode } },
    fetchPolicy: 'cache-first',
  });
  const isCoupon =
    codeString == referralCode && cachedDataCode?.type === 'COUPON';

  useEffect(() => {
    setOpen(Boolean(Boolean(codeString) && user));
  }, [codeString, user]);

  const handleClose = () => {
    setReferralCode('');
    if (isModalOpen) {
      const cleanParams = new URLSearchParams(router.asPath.split('?')[1]);
      cleanParams.delete('codigo');
      const pathname =
        router.asPath.split('?')[0] + '?' + cleanParams.toString();

      if (!pathname.includes('producto/')) {
        void router.replace(
          {
            pathname: '/inicio',
            query: removeUndefineds({ ...router.query, codigo: undefined }),
          },
          {
            pathname: pathname,
            query: removeUndefineds({
              ...modalRouter.query,
              codigo: undefined,
            }),
          },
        );
      }
    } else {
      void router.replace({
        pathname: router.pathname,
        query: {},
      });
    }
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <ReferredUserDiv>
          <IconContainer>
            <Image
              layout="fill"
              src="/assets/images/home/closeicon.webp"
              alt="closeIcon"
              objectFit="contain"
              onClick={handleClose}
            />
          </IconContainer>
          <DialogTitleTypography>¡Lo sentimos!</DialogTitleTypography>
          <DialogInfoTypography>
            Los $5.000 de descuento son para clientes nuevos
          </DialogInfoTypography>
          <DialogInfoTypography>
            {!isCoupon ? (
              <>
                Pero tu también <BoldSpan>puedes ganar descuentos</BoldSpan> por
                cada persona que compre con{' '}
                <LinkTypography
                  onClick={() => {
                    void router.push(getRoute('referral'));
                  }}
                >
                  tu link
                </LinkTypography>
              </>
            ) : (
              <>
                Pero tu también tienes{' '}
                <LinkTypography
                  onClick={() => {
                    void router.push(getRoute('referral'));
                  }}
                  style={{ display: 'inline' }}
                >
                  un link
                </LinkTypography>{' '}
                que puedes compartir para <BoldSpan>ganar descuentos</BoldSpan>
              </>
            )}
          </DialogInfoTypography>
          <ShareButtonDiv>
            <ShareButton variant="contained" onClick={handleClose}>
              {isModalOpen ? (!isCoupon ? 'Siguiente' : 'Aceptar') : 'Cerrar'}
            </ShareButton>
          </ShareButtonDiv>
        </ReferredUserDiv>
      </Dialog>
    </>
  );
}
