/*
 * File: validate
 * Project: meki
 * File Created: Monday, 26th September 2022 5:15:50 pm
 * Author: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Last Modified: Tuesday, 27th September 2022 12:04:30 pm
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2022 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { gql } from '@apollo/client';
import { ResolverParams } from '@queries/queries.interfaces';

export const ValidateReferenceCouponQuerie = () => gql`
  query validateReferenceCoupon($params: ValidateReferenceCouponParams!) {
    validateReferenceCoupon(params: $params) {
      isValid
      msg
      type
      sourceOption
      name
      lastName
      code
    }
  }
`;

export type ValidateReferenceCouponResponse = {
  validateReferenceCoupon: {
    isValid: boolean;
    msg: string;
    name: string;
    lastName: string;
    sourceOption: string;
    type: string;
    code: string;
  };
};

export type ValidateReferenceCouponParams = ResolverParams<{
  code: string;
}>;
